<template>
	<div class="app-body">
		<div class="a-flex-rsbc a-ptb-6">
			<span class="a-fs-16 a-fw-700">离线卡管理</span>
			<div class="a-flex-rcc">
				<el-button icon="el-icon-upload2" class="s-btn-exp" @click="exportfile" style="margin-right:10px">导出</el-button>
				<!-- <el-button icon="el-icon-plus" type="primary" class="s-btn-add" style="margin-right:10px" @click="rechargeBack">充值</el-button> -->
				<el-dropdown placement="bottom-start" @command="handlerAddCard">
					<el-button icon="el-icon-plus" type="primary" class="s-btn-add">离线卡</el-button>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item command="a">XLS批量导入</el-dropdown-item>
						<el-dropdown-item command="b">新增卡片</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>
		<el-card class="el-main">
			<le-search-form @reset="handlerRest" @search="handlerSearch" id="leSearch">
				<le-input label="卡号" v-model="pageParam.params.cardNo" placeholder="请输入卡号" />
			</le-search-form>
			<le-pagview @setData="setTableData" :pageParam="pageParam" v-if="pageParam" :isFixed="true" :tableRef="$refs.chargeCardOffList">
				<el-table ref="chargeCardOffList" :data="tableData" :highlight-current-row="true" v-sticky="{ top: 0, parent:'.el-card__body' }" style="width: 100%">
					<el-table-column prop="cardNo" label="卡号">
					</el-table-column>
					<el-table-column label="当前余额">
						<template slot-scope="{ row }">
							<span v-if="row.balance < 0">-&yen;{{ util.numFormat(Math.abs(row.balance))
                            }}</span>
							<span v-else>&yen;{{ util.numFormat(row.balance) }}</span>
						</template>
					</el-table-column>
					<el-table-column label="充电次数">
						<template slot-scope="{ row }">
							<span>{{ row.chargingNum }}次</span>
						</template>
					</el-table-column>
					<el-table-column label="是否停用">
						<template slot-scope="{ row }">
							<span>{{ row.deactivateText }}</span>
						</template>
					</el-table-column>
				</el-table>
			</le-pagview>

		</el-card>
		<le-upload-file-img v-show="false" :uploadUrl="$Config.apiUrl.batchImportDev" :acceptArr="['.xls', '.xlsx']"
			notnull ref="pic" label="" @handleSuccess="importDevSuccess" v-model="deviceXLS"></le-upload-file-img>
		<!-- <le-upload-file-img v-show="false" :uploadUrl="$Config.apiUrl.batchImportDev" :acceptArr="['.xls', '.xlsx']"
			notnull ref="recharge" label="" @handleSuccess="importDevSuccess" v-model="deviceXLS"></le-upload-file-img> -->
		<el-dialog title="新增卡片" :visible.sync="dialogAddCard" width="30%" class="addDialog">

			<el-form :model="form">
				<el-form-item label="卡号" :label-width="'112px'">
					<el-input v-model="form.cardNo" autocomplete="off" placeholder="请输入卡号"></el-input>

				</el-form-item>
				<el-form-item label="充值金额" :label-width="'112px'" class="a-mtb-24">
					<el-input placeholder="请输入内容" v-model="form.amount" type="number">
						<template slot="prepend">&yen;</template>
					</el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="resetForm">取消</el-button>
				<el-button type="primary" @click="handlerSubmit">确定</el-button>
			</div>


		</el-dialog>
	</div>
</template>
<script>
	import util from '../../utils/util';
	export default {
		data() {
			return {
				util: util,
				dialogAddCard: false,
				tableData: [],
				pageParam: null,
				deviceXLS: '',
				fileUrl: '', //文件导出url
				form: {
					cardNo: "",
					amount: ""
				}
			}
		},
		methods: {
			handlerAddCard(command) {
				if (command == 'a') {
					// 批量导入
					this.$refs['pic'].$refs["upload"].$refs["upload-inner"].handleClick();
				} else if (command == 'b') {
					this.dialogAddCard = true
				}
			},
			handlerSearch() {
				this.pageParam.freshCtrl++;
			},
			setTableData(data, fileUrl) {
				this.tableData = data;
				this.fileUrl = fileUrl

			},
			// rechargeBack () {
			// 	this.$refs['recharge'].$refs["upload"].$refs["upload-inner"].handleClick();
			// },
			importDevSuccess() {
				this.$message.success('导入成功')
				this.pageParam.freshCtrl++; // 重新请求表格数据
			},
			
			handlerRest() {
				this.pageParam.params = {
					exportFlag: false,
					cardNo: '',
				};
				this.dialogAddCard = false
			},
			handlerSubmit() {
				if (!this.form.cardNo.trim()) {
					this.dialogAddCard = false
					this.form.cardNo = ''
					this.form.amount = ''
					return false
				}
				this.$Axios._post({
					url: this.$Config.apiUrl.addOffLineCard,
					params: {
						"cardNo": this.form.cardNo,
						'amount': this.form.amount * 100
					}
				}).then((res) => {
					if (res.result.code == 0) {
						this.$notify({
							title: res.result.data,
							type: 'success'
						})
						this.dialogAddCard = false
						this.form.cardNo = ''
						this.form.amount = ''
						this.pageParam.freshCtrl++;
					} else {
						this.$message.error(res.result.message)
					}

				}).catch((error) => {
					this.$message.error(error);
				})
			}
		},
		created() {
			this.pageParam = {
				url: this.$Config.apiUrl.offLineCardList,
				method: "post",
				params: {
					exportFlag: true,
					cardNo: '',
					startTime: '',
					endTime: ''
				},
				freshCtrl: 1,
			};
		},
	}
</script>
<style lang="scss" scoped>
	/deep/ .s-search-label {
		justify-content: flex-start;
		width: 72px !important;
	}

	.addDialog /deep/ .el-dialog__header {
		font-size: 16px;
		color: #303133;
		font-weight: bold;
		padding: 16px 24px;

	}

	.addDialog /deep/ .el-dialog__body {
		border-top: 1px solid #EBF0F5;
		border-bottom: 1px solid #EBF0F5;
		height: 136px;
		padding-left: 24px;
		padding-right: 24px;
		padding-top: 24px;
	}

	.addDialog /deep/ .el-dialog__footer {
		padding-left: 24px;
		padding-right: 24px;
	}

	.addDialog /deep/ .el-form-item__label {
		text-align: left;

	}
	/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
		width: 0 !important;
		height: 0 !important;
	}
	/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
		height: 100% !important;
	}
</style>
